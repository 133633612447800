function setupContactForm() {
    var forms = document.getElementsByClassName('contact-form');
    if (forms.length > 0) {
        var form = forms[0];
        form.addEventListener("submit", function(e){
            e.preventDefault();
            var formSubmitted = this;
            document.getElementById('contact-form-spinner').classList.remove('d-none');

            var data = serialize(formSubmitted);
            var http = new XMLHttpRequest();
            http.open('POST', formSubmitted.getAttribute('data-action'), true);
            http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            http.onreadystatechange = function() {//Call a function when the state changes.
                if(http.readyState == 4 && http.status == 200) {
                    var response = JSON.parse(http.response);
                    setTimeout(function () {
                        formSubmitted.parentNode.innerHTML = response.html;
                        if (!response.result) {
                            recaptchaOnloadCallback();
                            // setupSelects();
                            setupContactForm();
                        }
                    }, 500);
                }
            };
            http.send(data);
        });
    }
}

setupContactForm();