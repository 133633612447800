/**
 * setta un cookie
 */
function setCookie(cname, cvalue, exdays) {

    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires +"; path=/";

    return getCookie(cname);
}

/**
 * ritorna il valore del cookie
 */
function getCookie(cname) {

    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
    }
    return "";
}

/**
 * cancella il cookie
 */
function eraseCookie(name) {

    setCookie(name,"",-1);
}

/**
 * aggiunge un valore a un cookie multivalore (es. stops)
 */
function addValueToCookie(cname, cvalue, exdays) {

    var values = getCookie(cname);
    if(values) {
        values = JSON.parse(values);
    } else {
        values = new Array();
    }
    values.push(cvalue);
    values = JSON.stringify(array_unique(values));

    eraseCookie(cname);
    setCookie(cname, values, exdays);
}

/**
 * aggiunge un valore a un cookie multivalore (es. stops)
 */
function removeValueFromCookie(cname, cvalue, exdays) {

    var values = getCookie(cname);
    if(values) {
        values = JSON.parse(values);
        var newValues = new Array();
        for(var i = 0; i < values.length; i ++) {
            if(values[i] != cvalue) {
                newValues.push(values[i]);
            }
        }
    } else {
        var newValues = new Array();
    }
    values = JSON.stringify(array_unique(newValues));

    eraseCookie(cname);
    setCookie(cname, values, exdays);
}
/**
 * cerca un valore in un cookie multivalore (es. stops)
 */
function cookieHasValue(cname, cvalue) {
    if (getCookie(cname)) {
        var values = JSON.parse(getCookie(cname));

        for(var i = 0; i < values.length; i ++) {
            if(values[i] == cvalue) {
                return true;
            }
        }
    }

    return false;
}
