var searchSuggestionsOpen = false;

if (typeof searchSuggestionsUrl == 'undefined' ) {
    var searchSuggestionsUrl = '';
}

var searchRequestTiming = false;
var searchPhrases = [];

$(function () {
    // $('.searchTerm').on('focus', function (e) {
    //     var term = $(this).val();
        var current_searchRequestTiming = uniqid();
        searchRequestTiming = current_searchRequestTiming;

        console.log(current_searchRequestTiming, searchRequestTiming);
        searchSuggestionsOpen = true;
        if (current_searchRequestTiming == searchRequestTiming) {
            console.log('   '+current_searchRequestTiming+' > start call', searchRequestTiming);
            setTimeout(function () {
                $.post(searchSuggestionsUrl, {
                    q: ''
                }, function (data) {

                    if (current_searchRequestTiming == searchRequestTiming) {
                        searchPhrases = data;
                        console.log('       '+current_searchRequestTiming+' > start call > show results', searchRequestTiming, data);
                        var ul = $('<ul></ul>');
                        if (data.length > 0) {
                            for (var i = 0; i < data.length; i++) {
                                ul.append($('<li class="C(black) Ff(gotham_book) text_14-to-15"><a class="C(black) Ff(gotham_book) text_14-to-15" href="' + data[i].path + '">' + data[i].phrase + '</a></li>'));
                            }
                            $('.searchSuggestionsList').html('');
                            $('.searchSuggestionsList').append(ul);
                            // $('.searchSuggestions').removeClass('D(n)');
                        }
                    }
                });
            }, 250);
        }
    // });
    $('.searchTerm').on('change paste keyup', function (e) {
        var current_searchRequestTiming = uniqid();
        searchRequestTiming = current_searchRequestTiming;

        console.log(current_searchRequestTiming, searchRequestTiming);
        var term = $(this).val();
        if (current_searchRequestTiming == searchRequestTiming) {
            console.log('   '+current_searchRequestTiming+' > start call', searchRequestTiming);
            setTimeout(function () {
                $.post(searchSuggestionsUrl, {
                    q: term
                }, function (data) {

                    if (current_searchRequestTiming == searchRequestTiming) {
                        console.log('       '+current_searchRequestTiming+' > start call > show results', searchRequestTiming, data);

                        var ul = $('<ul></ul>');
                        if (data.length > 0) {
                            searchSuggestionsOpen = true;
                            for (var i = 0; i < data.length; i++) {
                                ul.append($('<li class="C(endeavour) Ff(gotham_book) text_14-to-15"><a href="' + data[i].path + '">' + data[i].phrase + '</a></li>'));
                            }
                            $('.searchSuggestionsList').html('');
                            $('.searchSuggestionsList').append(ul);
                            $('.searchSuggestions').removeClass('D(n)');
                        } else {
                            searchSuggestionsOpen = false;
                            $('.searchSuggestions').addClass('D(n)');
                            $('.searchSuggestionsList').html('');
                        }
                    }
                });
            }, 750);
        }
    });
    // $(document).on('click', function (e) {
    //     if (!$(e.target).hasClass('searchTerm')) {
    //         console.log('click', 'document', e);
    //         if (searchSuggestionsOpen) {
    //             searchSuggestionsOpen = false;
    //             $('.searchTerm').val('');
    //             $('.searchSuggestions').addClass('D(n)');
    //         }
    //     }
    // });

    $('.searchTerm').on('focusout', function () {
        setTimeout(function () {
            $('.searchSuggestions').addClass('D(n)');
        }, 250);
    });

    $('.toggleSearchSuggestions').on('focus', function () {
        if (searchPhrases.length > 0) {
            $('#'+$(this).attr('data-searchSuggestions')).removeClass('D(n)');
        }
    });
});
