/**
 * mostra il preload
 */
function showPreload() {
    document.getElementById('preload').classList.remove('d-none');
    document.getElementById('preload-overlay').classList.remove('d-none');
}

/**
 * gestisce select preload
 */
$(document).on('change', '#select_area_areas', function () {
    var areaID = document.querySelector('input[name="select_area[areas]"]:checked').value;
    setCookie('AREA_ID', areaID, 365);
    document.getElementById('preload').classList.add('d-none');
    document.getElementById('preload-overlay').classList.add('d-none');
    location.reload();
});

/**
 * chiusura preload
 */
document.getElementById('preload__close').addEventListener('click', function () {
    var areaId = getCookie('AREA_ID');
    setCookie('AREA_ID', (areaId ? areaId : defaultAreaId), 365);
    document.getElementById('preload').classList.add('d-none');
    document.getElementById('preload-overlay').classList.add('d-none');
    if(!areaId) {
        location.reload();
    }
});