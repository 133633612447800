// // var body = document.querySelector('body');
//
//
// // if (body.classList.contains('bubble')) {
//     var bubble1 = document.getElementsByClassName('bubble_1')[0].getElementsByTagName('path')[0];
//     var bubble2 = document.getElementsByClassName('bubble_2')[0].getElementsByTagName('path')[0];
//     var bubble3 = document.getElementsByClassName('bubble_3')[0].getElementsByTagName('path')[0];
//     var bubble4 = document.getElementsByClassName('bubble_4')[0].getElementsByTagName('path')[0];
//     var bubble5 = document.getElementsByClassName('bubble_5')[0].getElementsByTagName('path')[0];
//     var bubble6 = document.getElementsByClassName('bubble_6')[0].getElementsByTagName('path')[0];
//     var bubble7 = document.getElementsByClassName('bubble_7')[0].getElementsByTagName('path')[0];
//     var bubble8 = document.getElementsByClassName('bubble_8')[0].getElementsByTagName('path')[0];
//     var bubble9 = document.getElementsByClassName('bubble_9')[0].getElementsByTagName('path')[0];
// // }
//
//
//
// anime({
//     targets: bubble1,
//     d: [
//         { value: 'm904.891,938.157c48.962,-25.217 53.157,-126.578 40.593,-153c-13.688,-28.783 -96.735,-78.152 -174.992,-36.827c-43.901,23.183 -17.68,90.016 10.271,120.963c24.143,26.737 75.478,93.918 124.128,68.864z' },
//         { value: 'm917.813,869.293c27.95,-30.947 54.171,-97.78 10.27,-120.963c-78.257,-41.325 -161.305,8.044 -174.992,36.827c-12.565,26.422 -8.369,127.783 40.593,153c48.649,25.054 99.984,-42.127 124.129,-68.864z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 8500,
//     // endDelay: 10,
//     loop: true
// });
//
// anime({
//     targets: bubble2,
//     d: [
//         { value: 'm1054.086,534.311c-7.617,12.375 -21.013,56.125 -4.5,61.288c29.433,9.205 47.899,1.199 61.497,-9c12.916,-7.5 24.316,-22.076 4.938,-48.582c-13.938,-15.915 -55.354,-14.398 -61.935,-3.706z' },
//         { value: 'm1068.195,543.026c-7.617,12.375 -12.37,36.938 4.143,42.101c29.433,9.205 54.765,-12.973 57.631,-23.702c2.633,-9.85 -5.225,-44.317 -23.6,-49.858c-18.257,-5.505 -31.593,20.768 -38.174,31.459z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 9000,
//     // endDelay: 10,
//     loop: true
// });
//
// anime({
//     targets: bubble3,
//     d: [
//         { value: 'm720.274,1048.576c-6.687,-5.479 -37.213,3.818 -41.574,13.141c-7.777,16.615 3.273,33.32 9.37,35.867c5.597,2.34 26.543,0.459 31.276,-9.93c4.705,-10.323 6.706,-34.342 0.928,-39.078z' },
//         { value: 'm703.85,1062.637c-6.687,-5.479 -20.788,-10.242 -25.15,-0.92c-7.777,16.615 3.274,33.32 9.37,35.867c5.597,2.34 26.543,0.459 31.277,-9.93c4.704,-10.323 -9.72,-20.282 -15.497,-25.017z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 8000,
//     // endDelay: 10,
//     loop: true
// });
//
// anime({
//     targets: bubble4,
//     d: [
//         { value: 'm237.646,507.872c5.354,19.128 33.354,39.092 67.978,38.886c35.876,-5.258 32.029,-40.283 29.616,-54.654c-3.046,-18.137 -31.001,-34.063 -48.985,-36.104c-17.869,-2.027 -53.255,23 -48.609,51.872z' },
//         { value: 'm274.786,508.203c-4.98,12.768 -5.247,36.359 10.946,38.364c28.862,3.574 48.625,-21.277 49.474,-31.715c0.781,-9.584 -12.325,-40.238 -30.309,-42.279c-17.868,-2.027 -25.809,24.599 -30.111,35.63z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 9000,
//     // endDelay: 10,
//     loop: true
// });
//
// anime({
//     targets: bubble5,
//     d: [
//         { value: 'm1682.554,1072.712c-4.979,12.769 10.985,39.618 27.179,41.622c28.861,3.574 48.625,-21.277 49.475,-31.715c0.78,-9.584 -12.326,-40.238 -30.311,-42.279c-17.868,-2.028 -42.042,21.341 -46.343,32.372z' },
//         { value: 'm1698.786,1075.971c-4.979,12.768 -5.247,36.359 10.946,38.363c28.862,3.574 48.625,-21.277 49.475,-31.715c0.78,-9.584 -12.326,-40.238 -30.31,-42.279c-17.868,-2.027 -25.809,24.6 -30.111,35.631z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 9000,
//     // endDelay: 10,
//     loop: true
// });
//
// anime({
//     targets: bubble6,
//     d: [
//         { value: 'm91.62,873.085c37.846,-85.449 95.51,-151.492 225.563,-163.492c65.542,-6.047 248.428,14.491 222.428,188.991s-51.399,295.285 -237.313,279.155c-64.181,-5.568 -255.268,-203.979 -210.678,-304.654z' },
//         { value: 'm126.5,925c-52,-96 -42.054,-238 88,-250c65.542,-6.047 400,23.5 374,198s-50.086,298.13 -236,282c-64.181,-5.568 -173.558,-133.184 -226,-230z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 12500,
//     // endDelay: 10,
//     loop: true
// });
//
// anime({
//     targets: bubble7,
//     d: [
//         { value: 'm410.618,522.103c53.033,90.941 196.482,138.123 370.482,140.123s291.487,-209.106 247.487,-479.106s-515.471,-167.497 -583.471,-67.497s-86.859,316.689 -34.498,406.48z' },
//         { value: 'm377.859,294.212c0,0 96,366 270,368s438,-140 394,-410s-480,-280 -548,-180s-136,120 -116,222z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 20000,
//     // endDelay: 10,
//     loop: true
// });
//
// anime({
//     targets: bubble8,
//     d: [
//         { value: 'm37.18,353.943c-45.627,125.792 169.291,349.102 327.82,364.885c282.584,28.133 276.861,-125.805 282.711,-227.909c5.377,-93.725 -100.188,-474.379 -276.214,-490.089c-174.905,-15.601 -300.997,227.67 -334.317,353.113z' },
//         { value: 'm85.894,355.685c-45.627,125.793 -42.693,356.154 115.836,371.937c282.584,28.133 469.677,-219.076 475.527,-321.181c5.377,-93.724 -129.734,-389.901 -305.76,-405.611c-174.905,-15.601 -246.188,246.182 -285.603,354.855z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 10000,
//     // endDelay: 10,
//     loop: true
// });
//
// anime({
//     targets: bubble9,
//     d: [
//         { value: 'm680.818,193.557c-34.704,-74.35 -176.318,-120.408 -364.318,-131.057s-327.044,110 -302.785,347s279.666,333.198 439.785,311.448c161,-21.87 363.564,-235.493 227.318,-527.391z' },
//         { value: 'm685.74,295.679c-18.185,-80.01 -53.24,-211.88 -241.24,-222.529s-458.52,-2.65 -434.26,234.35s128.141,417.75 288.26,396c161,-21.87 459.742,-88.815 387.24,-407.821z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 10000,
//     // endDelay: 10,
//     loop: true
// });
//
//
//
//
//
// // var bubble10 = document.getElementsByClassName('bubble_10')[0].getElementsByTagName('path')[0];
// // var bubble11 = document.getElementsByClassName('bubble_11')[0].getElementsByTagName('path')[0];
//
// var bubble10 = document.querySelectorAll('.bubble_10');
//
// anime({
//     targets: bubble10,
//     d: [
//         { value: 'm36.667,114.332c-2.65,71.995 20.183,169.845 84.333,146.703s173.429,-172.012 134,-223.702c-20.541,-26.928 -83.467,-29.502 -136.667,-19c-48.921,9.657 -80.396,61.51 -81.666,95.999z' },
//         { value: 'm6.65,102.671c-2.65,71.995 144.049,174.611 208.2,151.47s58.341,-145.093 18.912,-196.784c-20.541,-26.928 -84.726,-49.221 -137.926,-38.719c-48.921,9.657 -87.916,49.544 -89.186,84.033z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 20000,
//     // endDelay: 10,
//     loop: true
// });
//
//
// var bubble11 = document.querySelectorAll('.bubble_11');
//
// anime({
//     targets: bubble11,
//     d: [
//         { value: 'm46.333,92.333c-33.442,97.843 -14.748,147.258 40.667,167.333s104.389,-31.29 143.487,-90.804c16.545,-25.184 70.896,-138.861 0.846,-155.53c-93.988,-22.364 -167.023,26.408 -185,79.001z' },
//         { value: 'm31.667,83.999c-33.442,97.843 -8.164,140.183 47.251,160.258s160.921,5.528 175.082,-64.258c6.536,-32.21 11.383,-145.332 -58.667,-162c-93.988,-22.365 -145.689,13.407 -163.666,66z' },
//     ],
//     // easing: 'linear',
//     easing: 'easeInOutSine',
//     // direction: 'alternate',
//     duration: 10000,
//     // endDelay: 10,
//     loop: true
// });









if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    // alert('Its Safari');
} else {

    if( $('.bubble_1').length ) {
        // console.log('bubble_1 exist');
        var bubble1 = document.querySelectorAll('.bubble_1');

        anime({
            targets: bubble1,
            d: [
                { value: 'm904.891,938.157c48.962,-25.217 53.157,-126.578 40.593,-153c-13.688,-28.783 -96.735,-78.152 -174.992,-36.827c-43.901,23.183 -17.68,90.016 10.271,120.963c24.143,26.737 75.478,93.918 124.128,68.864z' },
                { value: 'm917.813,869.293c27.95,-30.947 54.171,-97.78 10.27,-120.963c-78.257,-41.325 -161.305,8.044 -174.992,36.827c-12.565,26.422 -8.369,127.783 40.593,153c48.649,25.054 99.984,-42.127 124.129,-68.864z' },
            ],
            easing: 'easeInOutSine',
            duration: 8500,
            loop: true
        });
    }

    if( $('.bubble_2').length ) {
        // console.log('bubble_2 exist');
        var bubble2 = document.querySelectorAll('.bubble_2');

        anime({
            targets: bubble2,
            d: [
                { value: 'm1054.086,534.311c-7.617,12.375 -21.013,56.125 -4.5,61.288c29.433,9.205 47.899,1.199 61.497,-9c12.916,-7.5 24.316,-22.076 4.938,-48.582c-13.938,-15.915 -55.354,-14.398 -61.935,-3.706z' },
                { value: 'm1068.195,543.026c-7.617,12.375 -12.37,36.938 4.143,42.101c29.433,9.205 54.765,-12.973 57.631,-23.702c2.633,-9.85 -5.225,-44.317 -23.6,-49.858c-18.257,-5.505 -31.593,20.768 -38.174,31.459z' },
            ],
            easing: 'easeInOutSine',
            duration: 9000,
            loop: true
        });
    }

    if( $('.bubble_3').length ) {
        // console.log('bubble_3 exist');
        var bubble3 = document.querySelectorAll('.bubble_3');

        anime({
            targets: bubble3,
            d: [
                { value: 'm720.274,1048.576c-6.687,-5.479 -37.213,3.818 -41.574,13.141c-7.777,16.615 3.273,33.32 9.37,35.867c5.597,2.34 26.543,0.459 31.276,-9.93c4.705,-10.323 6.706,-34.342 0.928,-39.078z' },
                { value: 'm703.85,1062.637c-6.687,-5.479 -20.788,-10.242 -25.15,-0.92c-7.777,16.615 3.274,33.32 9.37,35.867c5.597,2.34 26.543,0.459 31.277,-9.93c4.704,-10.323 -9.72,-20.282 -15.497,-25.017z' },
            ],
            easing: 'easeInOutSine',
            duration: 8000,
            loop: true
        });
    }

    if( $('.bubble_4').length ) {
        // console.log('bubble_4 exist');
        var bubble4 = document.querySelectorAll('.bubble_4');

        anime({
            targets: bubble4,
            d: [
                { value: 'm237.646,507.872c5.354,19.128 33.354,39.092 67.978,38.886c35.876,-5.258 32.029,-40.283 29.616,-54.654c-3.046,-18.137 -31.001,-34.063 -48.985,-36.104c-17.869,-2.027 -53.255,23 -48.609,51.872z' },
                { value: 'm274.786,508.203c-4.98,12.768 -5.247,36.359 10.946,38.364c28.862,3.574 48.625,-21.277 49.474,-31.715c0.781,-9.584 -12.325,-40.238 -30.309,-42.279c-17.868,-2.027 -25.809,24.599 -30.111,35.63z' },
            ],
            easing: 'easeInOutSine',
            duration: 9000,
            loop: true
        });
    }

    if( $('.bubble_5').length ) {
        // console.log('bubble_5 exist');
        var bubble5 = document.querySelectorAll('.bubble_5');

        anime({
            targets: bubble5,
            d: [
                { value: 'm1682.554,1072.712c-4.979,12.769 10.985,39.618 27.179,41.622c28.861,3.574 48.625,-21.277 49.475,-31.715c0.78,-9.584 -12.326,-40.238 -30.311,-42.279c-17.868,-2.028 -42.042,21.341 -46.343,32.372z' },
                { value: 'm1698.786,1075.971c-4.979,12.768 -5.247,36.359 10.946,38.363c28.862,3.574 48.625,-21.277 49.475,-31.715c0.78,-9.584 -12.326,-40.238 -30.31,-42.279c-17.868,-2.027 -25.809,24.6 -30.111,35.631z' },
            ],
            easing: 'easeInOutSine',
            duration: 9000,
            loop: true
        });
    }

    if( $('.bubble_6').length ) {
        // console.log('bubble_6 exist');
        var bubble6 = document.querySelectorAll('.bubble_6');

        anime({
            targets: bubble6,
            d: [
                { value: 'm91.62,873.085c37.846,-85.449 95.51,-151.492 225.563,-163.492c65.542,-6.047 248.428,14.491 222.428,188.991s-51.399,295.285 -237.313,279.155c-64.181,-5.568 -255.268,-203.979 -210.678,-304.654z' },
                { value: 'm126.5,925c-52,-96 -42.054,-238 88,-250c65.542,-6.047 400,23.5 374,198s-50.086,298.13 -236,282c-64.181,-5.568 -173.558,-133.184 -226,-230z' },
            ],
            easing: 'easeInOutSine',
            duration: 12500,
            loop: true
        });
    }

    if( $('.bubble_7').length ) {
        // console.log('bubble_7 exist');
        var bubble7 = document.querySelectorAll('.bubble_7');

        anime({
            targets: bubble7,
            d: [
                { value: 'm410.618,522.103c53.033,90.941 196.482,138.123 370.482,140.123s291.487,-209.106 247.487,-479.106s-515.471,-167.497 -583.471,-67.497s-86.859,316.689 -34.498,406.48z' },
                { value: 'm377.859,294.212c0,0 96,366 270,368s438,-140 394,-410s-480,-280 -548,-180s-136,120 -116,222z' },
            ],
            easing: 'easeInOutSine',
            duration: 20000,
            loop: true
        });
    }

    if( $('.bubble_8').length ) {
        // console.log('bubble_8 exist');
        var bubble8 = document.querySelectorAll('.bubble_8');

        anime({
            targets: bubble8,
            d: [
                { value: 'm37.18,353.943c-45.627,125.792 169.291,349.102 327.82,364.885c282.584,28.133 276.861,-125.805 282.711,-227.909c5.377,-93.725 -100.188,-474.379 -276.214,-490.089c-174.905,-15.601 -300.997,227.67 -334.317,353.113z' },
                { value: 'm85.894,355.685c-45.627,125.793 -42.693,356.154 115.836,371.937c282.584,28.133 469.677,-219.076 475.527,-321.181c5.377,-93.724 -129.734,-389.901 -305.76,-405.611c-174.905,-15.601 -246.188,246.182 -285.603,354.855z' },
            ],
            easing: 'easeInOutSine',
            duration: 10000,
            loop: true
        });
    }

    if( $('.bubble_9').length ) {
        // console.log('bubble_9 exist');
        var bubble9 = document.querySelectorAll('.bubble_9');

        anime({
            targets: bubble9,
            d: [
                { value: 'm680.818,193.557c-34.704,-74.35 -176.318,-120.408 -364.318,-131.057s-327.044,110 -302.785,347s279.666,333.198 439.785,311.448c161,-21.87 363.564,-235.493 227.318,-527.391z' },
                { value: 'm685.74,295.679c-18.185,-80.01 -53.24,-211.88 -241.24,-222.529s-458.52,-2.65 -434.26,234.35s128.141,417.75 288.26,396c161,-21.87 459.742,-88.815 387.24,-407.821z' },
            ],
            easing: 'easeInOutSine',
            duration: 10000,
            loop: true
        });
    }

    if( $('.bubble_10').length ) {
        // console.log('bubble_10 exist');
        var bubble10 = document.querySelectorAll('.bubble_10');

        anime({
            targets: bubble10,
            d: [
                { value: 'm36.667,114.332c-2.65,71.995 20.183,169.845 84.333,146.703s173.429,-172.012 134,-223.702c-20.541,-26.928 -83.467,-29.502 -136.667,-19c-48.921,9.657 -80.396,61.51 -81.666,95.999z' },
                { value: 'm6.65,102.671c-2.65,71.995 144.049,174.611 208.2,151.47s58.341,-145.093 18.912,-196.784c-20.541,-26.928 -84.726,-49.221 -137.926,-38.719c-48.921,9.657 -87.916,49.544 -89.186,84.033z' },
            ],
            easing: 'easeInOutSine',
            duration: 20000,
            loop: true
        });
    }

    if( $('.bubble_11').length ) {
        // console.log('bubble_11 exist');
        var bubble11 = document.querySelectorAll('.bubble_11');

        anime({
            targets: bubble11,
            d: [
                { value: 'm46.333,92.333c-33.442,97.843 -14.748,147.258 40.667,167.333s104.389,-31.29 143.487,-90.804c16.545,-25.184 70.896,-138.861 0.846,-155.53c-93.988,-22.364 -167.023,26.408 -185,79.001z' },
                { value: 'm31.667,83.999c-33.442,97.843 -8.164,140.183 47.251,160.258s160.921,5.528 175.082,-64.258c6.536,-32.21 11.383,-145.332 -58.667,-162c-93.988,-22.365 -145.689,13.407 -163.666,66z' },
            ],
            easing: 'easeInOutSine',
            duration: 10000,
            loop: true
        });
    }
}
