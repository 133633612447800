var configMenu = {
    active: false
};

var menuDesktop = document.getElementsByClassName('menuDesktop')[0];
var subMenu = document.getElementsByClassName('menu__link_submenu')[0];
var menuWave = document.getElementsByClassName('js-menu-wave')[0].getElementsByTagName('path')[0];



/**
 * Function click on submenu
 */
subMenu.onclick = function() {

    if(configMenu.active) {
        menuClose();
    } else {
        menuOpen();
    }
};




/**
 * Function open menu
 */
function menuOpen() {
    configMenu.active = true;
    menuDesktop.classList.add('is-active');

    anime({
        targets: menuWave,
        d: [
            { value: 'm548,120c400,18 472,-76 922,-100c176.239,-9.399 329.16,12.492 450,42.606l0,-62.606l-1920,0l0,69.477c117.302,17.434 299.964,39.361 548,50.523z' }
        ],
        easing: 'linear',
        duration: 600
    });

}


/**
 * Function close menu
 */
function menuClose() {

    anime({
        targets: menuWave,
        d: [
            { value: 'm544,0c400,0 466,0 930,0c176.489,0 302,-6.692 446,0l0,0l-1920,0l0,0c120,0 295.713,0 544,0z' },
        ],
        easing: 'linear',
        duration: 600,
        delay: 350,
        complete: function() {
            menuDesktop.classList.remove('is-active');

            configMenu.active = false;
        }
    });
}







var configMenuMobile = {
    active: false
};

var configSubMenuMobile = {
    active: false
};

var hamburger = document.getElementsByClassName('hamburger')[0];
var menuMobile = document.getElementsByClassName('menuMobile')[0];
var subMenuMobileLink = document.getElementsByClassName('menu__link_submenu')[1];
var subMenuMobile = document.getElementsByClassName('subMenuMobile')[0];
var goBack = document.getElementsByClassName('goBack')[0];
var menuWaveMobile = document.getElementsByClassName('js-menu-wave')[1].getElementsByTagName('path')[0];
var subMenuWaveMobile = document.getElementsByClassName('js-menu-wave')[2].getElementsByTagName('path')[0];






/**
 * Function click on hamburger
 */
hamburger.onclick = function() {

    if(configMenuMobile.active) {
        menuMobileClose();
        subMenuMobileClose();
    } else {
        menuMobileOpen();
    }
};




/**
 * Function open menu
 */
function menuMobileOpen() {
    configMenuMobile.active = true;
    menuMobile.classList.add('is-active');
    hamburger.classList.add('open');

    anime({
        targets: menuWaveMobile,
        d: [
            { value: 'm548,120c400,18 472,-76 922,-100c176.239,-9.399 329.16,12.492 450,42.606l0,-62.606l-1920,0l0,69.477c117.302,17.434 299.964,39.361 548,50.523z' }
        ],
        easing: 'linear',
        duration: 600
    });

}


/**
 * Function close menu
 */
function menuMobileClose() {

    anime({
        targets: menuWaveMobile,
        d: [
            { value: 'm544,0c400,0 466,0 930,0c176.489,0 302,-6.692 446,0l0,0l-1920,0l0,0c120,0 295.713,0 544,0z' },
        ],
        easing: 'linear',
        duration: 600,
        // delay: 350,
        complete: function() {
            menuMobile.classList.remove('is-active');
            hamburger.classList.remove('open');

            configMenuMobile.active = false;
        }
    });
}





/**
 * Function click on submenu
 */
subMenuMobileLink.onclick = function() {

    if(configSubMenuMobile.active) {
        subMenuMobileClose();
    } else {
        subMenuMobileOpen();
    }
};

/**
 * Function open menu
 */
function subMenuMobileOpen() {
    configSubMenuMobile.active = true;
    subMenuMobile.classList.add('is-active');

    anime({
        targets: subMenuWaveMobile,
        d: [
            { value: 'm548,120c400,18 472,-76 922,-100c176.239,-9.399 329.16,12.492 450,42.606l0,-62.606l-1920,0l0,69.477c117.302,17.434 299.964,39.361 548,50.523z' }
        ],
        easing: 'linear',
        duration: 600
    });

}


/**
 * Function close menu
 */
function subMenuMobileClose() {

    anime({
        targets: subMenuWaveMobile,
        d: [
            { value: 'm544,0c400,0 466,0 930,0c176.489,0 302,-6.692 446,0l0,0l-1920,0l0,0c120,0 295.713,0 544,0z' },
        ],
        easing: 'linear',
        duration: 600,
        // delay: 350,
        complete: function() {
            subMenuMobile.classList.remove('is-active');

            configSubMenuMobile.active = false;
        }
    });
}




/**
 * Function click on submenu
 */
goBack.onclick = function() {

    if(configSubMenuMobile.active) {
        subMenuMobileClose();
    } else {
        subMenuMobileOpen();
    }
};



var searchButton = document.getElementById('searchButton');
var searchBar = document.getElementsByClassName('searchDesktop')[0];

searchButton.onclick = function() {
    searchBar.classList.toggle("open");
};
