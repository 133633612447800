
/**
 * setta un cookie
 */
function setCookie(cname, cvalue, exdays) {

    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires +"; path=/";

    return getCookie(cname);
}

/**
 * ritorna il valore del cookie
 */
function getCookie(cname) {

    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
    }
    return "";
}

/**
 * cancella il cookie
 */
function eraseCookie(name) {
    setCookie(name,"",-1);
}

function onConsent(preference) {
    console.log('onConsent', preference);
    setTimeout(function () {
        if (preference.consent) {
            dataLayer.push({
                'event': 'cookie_law_accepted',
                'consense_marketing': true,
                'anonymizeIp': true
            });
        } else if (preference.purposes) {
            if (preference.purposes[5]) {
                dataLayer.push({
                    'event': 'cookie_law_accepted',
                    'consense_marketing': true,
                    'anonymizeIp': false
                });
            } else if (preference.purposes[4]) {
                dataLayer.push({
                    'event': 'cookie_law_accepted',
                    'consense_marketing': false,
                    'anonymizeIp': false
                });
            } else {
                dataLayer.push({
                    'event': 'cookie_law_accepted',
                    'consense_marketing': false,
                    'anonymizeIp': true
                });
            }

        }
    }, 500);
}

var _iub = _iub || [];
_iub.csConfiguration = {
    "askConsentAtCookiePolicyUpdate": true,
    "countryDetection": true,
    "enableFadp": true,
    "enableLgpd": true,
    "enableUspr": true,
    "floatingPreferencesButtonDisplay": "bottom-left",
    "lang": "en-GB",
    "lgpdAppliesGlobally": false,
    "perPurposeConsent": true,
    "siteId": 2557185,
    "whitelabel": false,
    "cookiePolicyId": 54338673,
    "callback": {
        "onPreferenceFirstExpressed": function(event) {
            onConsent(event);
        }
    },
    "banner": {
        "acceptButtonColor": "#2166BB",
        "acceptButtonDisplay": true,
        // "backgroundOverlay": true,
        "closeButtonRejects": true,
        "customizeButtonDisplay": true,
        "explicitWithdrawal": true,
        "listPurposes": true,
        "position": "float-top-left",
        "rejectButtonColor": "#A3A3A3",
        "rejectButtonDisplay": true,
        "showPurposesToggles": true
    }
};